import React, { lazy, Suspense } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Loader from './components/Loader';
const Kezdolap = lazy(() => import('./components/KezdoLap'));
const Termekek = lazy(() => import('./components/Termekek'));
const AppAppBar = lazy(() => import('./components/AppAppBar'));
const Szolgaltatasok = lazy(() => import('./components/Szolgaltatasok'));
const Kapcsolat = lazy(() => import('./components/Kapcsolat'));





const bakeryTheme = createTheme({
  palette: {
    bakeryBrown: {
      main: '#D2B48C', // A nice bakery brown color (SaddleBrown)
      contrastText: '#5D3617', // White contrast text for better visibility
    },
  },
});

export default function App() {
  return (
    
    <ThemeProvider theme={bakeryTheme}>
    <Suspense fallback={<Loader />}> {/* Display a loading indicator */}
      <CssBaseline />
      <AppAppBar />
      <Kezdolap />
      <Termekek />
      <Szolgaltatasok />
      <Kapcsolat />
    </Suspense>
    </ThemeProvider>
  );
}
